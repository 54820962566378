import React, { useState } from "react"
import { Form, FormLayout, ButtonGroup, Button } from "@shopify/polaris"
import styled from "styled-components"
import CornerRadiusForm from "./CornerRadiusForm"
import ColorsForm from "./ColorsForm"
import TypographyForm from "./TypographyForm"
import GlobalForm from "./GlobalForm"
import ButtonsForm from "./ButtonsForm"
import OrderSummaryForm from "./OrderSummaryForm"
import { navigate } from "gatsby"
import equal from "deep-equal"

const ButtonWrapper = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  text-align: left;
`
export default function CustomDesignForms({
  brandingData,
  selected,
  dropdownButtonMarkup,
  onSubmit,
  isSubmitting,
  location,
  token,
  shop,
  host,
}) {
  const designSystem = brandingData?.checkoutBranding?.designSystem
  const customizations = brandingData?.checkoutBranding?.customizations
  console.log("designSystem is: ", designSystem, customizations)
  const initialEditingState = {
    //canvasAccent: designSystem?.colorPalette?.canvas?.accent || null,
    //canvasBackground: designSystem?.colorPalette?.canvas?.background || null,
    //canvasForeground: designSystem?.colorPalette?.canvas?.foreground || null,
    color1Accent: designSystem?.colorPalette?.color1?.accent || null,
    color1Background: designSystem?.colorPalette?.color1?.background || null,
    color1Foreground: designSystem?.colorPalette?.color1?.foreground || null,
    color2Accent: designSystem?.colorPalette?.color2?.accent || null,
    color2Background: designSystem?.colorPalette?.color2?.background || null,
    color2Foreground: designSystem?.colorPalette?.color2?.foreground || null,
    criticalAccent: designSystem?.colorPalette?.critical?.accent || null,
    criticalBackground:
      designSystem?.colorPalette?.critical?.background || null,
    criticalForeground:
      designSystem?.colorPalette?.critical?.foreground || null,
    interactiveAccent: designSystem?.colorPalette?.interactive?.accent || null,
    interactiveBackground:
      designSystem?.colorPalette?.interactive?.background || null,
    interactiveForeground:
      designSystem?.colorPalette?.interactive?.foreground || null,
    primaryAccent: designSystem?.colorPalette?.primary?.accent || null,
    primaryBackground: designSystem?.colorPalette?.primary?.background || null,
    primaryForeground: designSystem?.colorPalette?.primary?.foreground || null,
    formCornerRadiusBase: designSystem?.cornerRadius?.base || null,
    formCornerRadiusLarge: designSystem?.cornerRadius?.large || null,
    formCornerRadiusSmall: designSystem?.cornerRadius?.small || null,
    primaryBaseFont: designSystem?.typography?.primary?.base || null,
    primaryBaseWeight: designSystem?.typography?.primary?.base?.weight || 300,
    primaryBoldFont: designSystem?.typography?.primary?.bold || null,
    primaryBoldWeight: designSystem?.typography?.primary?.bold?.weight || 600,
    secondaryBaseFont: designSystem?.typography?.secondary?.base || null,
    secondaryBaseWeight:
      designSystem?.typography?.secondary?.base?.weight || 300,
    secondaryBoldFont: designSystem?.typography?.secondary?.bold || null,
    secondaryBoldWeight:
      designSystem?.typography?.secondary?.bold?.weight || 600,
    globalCornerRadius: customizations?.global?.cornerRadius || "BASE",
    faviconImage: customizations?.favicon?.mediaImageId || null,
    primaryButtonBackground:
      customizations?.primaryButton?.background || "SOLID",
    primaryButtonBlockPadding:
      customizations?.primaryButton?.blockPadding || "BASE",
    primaryButtonBorder: customizations?.primaryButton?.border || "FULL",
    primaryButtonCornerRadius:
      customizations?.primaryButton?.cornerRadius || "BASE",
    primaryButtonInlinePadding:
      customizations?.primaryButton?.inlinePadding || "BASE",
    secondaryButtonBackground:
      customizations?.secondaryButton?.background || "SOLID",
    secondaryButtonBlockPadding:
      customizations?.secondaryButton?.blockPadding || "BASE",
    secondaryButtonBorder: customizations?.secondaryButton?.border || "FULL",
    secondaryButtonCornerRadius:
      customizations?.secondaryButton?.cornerRadius || "BASE",
    secondaryButtonInlinePadding:
      customizations?.secondaryButton?.inlinePadding || "BASE",
    merchandiseThumbnailBorder:
      customizations?.merchandiseThumbnail?.border || "FULL",
    merchandiseThumbnailCornerRadius:
      customizations?.merchandiseThumbnail?.cornerRadius || "BASE",
  }
  const [state, setState] = useState(initialEditingState)
  const hasUnsavedChanges = !equal(state, initialEditingState)
  /* The form layout, created using Polaris and App Bridge components. */

  console.log("state", state)
  return (
    <Form onSubmit={() => {onSubmit(state)}}>
      <FormLayout>
        {selected === "Colors" && (
          <ColorsForm
            ButtonWrapper={ButtonWrapper}
            dropdownButtonMarkup={dropdownButtonMarkup}
            state={state}
            setState={setState}
          />
        )}
        {selected === "Forms" && (
          <CornerRadiusForm
            ButtonWrapper={ButtonWrapper}
            dropdownButtonMarkup={dropdownButtonMarkup}
            state={state}
            setState={setState}
          />
        )}
        {selected === "Typography" && (
          <TypographyForm
            ButtonWrapper={ButtonWrapper}
            dropdownButtonMarkup={dropdownButtonMarkup}
            state={state}
            setState={setState}
            token={token} shop={shop} host={host} 
          />
        )}
        {selected === "Global" && (
          <GlobalForm
            ButtonWrapper={ButtonWrapper}
            dropdownButtonMarkup={dropdownButtonMarkup}
            state={state}
            setState={setState}
            token={token} shop={shop} host={host} 
          />
        )}
        {selected === "Buttons" && (
          <ButtonsForm
            ButtonWrapper={ButtonWrapper}
            dropdownButtonMarkup={dropdownButtonMarkup}
            state={state}
            setState={setState}
          />
        )}
        {selected === "Order summary" && (
          <OrderSummaryForm
            ButtonWrapper={ButtonWrapper}
            dropdownButtonMarkup={dropdownButtonMarkup}
            state={state}
            setState={setState}
          />
        )}
      </FormLayout>
      {hasUnsavedChanges && (
        <div
          style={{
            marginTop: 50,
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            justifyContent: "flex-start",
          }}
        >
          <div>
            <ButtonGroup>
              <Button submit  loading={isSubmitting} variant="primary">
                Save
              </Button>
            </ButtonGroup>
          </div>
          <div style={{ marginRight: 30 }}>
            <Button
              variant="plain"
              tone={hasUnsavedChanges ? "success" : "critical"}
              onClick={() => {
                navigate("/app/settings", {
                  state: location.state,
                  replace: true,
                })
              }}
            >
              Discard Changes
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
}
